import { useMemo } from 'react';

import { WebPage } from 'schema-dts';

import { ContentAreaField, SeoFragment } from '@hultafors/solidgear/types';

interface UsePageMicroDataProps {
  content?: ContentAreaField[];
  seo?: SeoFragment;
  thing?: Partial<WebPage>;

}

export function usePageMicroData({ content, seo, thing = {} }: UsePageMicroDataProps): Partial<WebPage> {
  return useMemo(() => {
    const data: Partial<WebPage> = {};
    if (seo?.title) {
      data.name = seo.title.replace(/ ?\|.+$/, '');
    }
    if (seo?.description) {
      data.description = seo.description;
    }
    if (content?.length) {
      const found = content.find((item) => ('title' in item) && item.title);
      if (found && 'title' in found && found.title) {
        data.name = found.title;
      }
    }

    const rest = Object.fromEntries(Object.entries(thing).filter(([_, value]) => {
      return (typeof value === 'string' && !!value) || (typeof value !== 'string' && typeof value !== 'undefined' && value !== null);
    }));

    return {
      ...data,
      '@type': 'WebPage',
      ...rest,
    };
  }, [content, seo, thing]);
}
